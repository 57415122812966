import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosInstance } from "../../utils/axios";

import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const CREATE_GROUP_MUTATION = `
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      group {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;

const UPDATE_GROUP = `
  mutation UpdateGroup($id: ID!, $input: UpdateGroupInput!) {
    updateGroup(id: $id, input: $input) {
      group {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;

const DELETE_GROUP = `
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "name", alignment: "left", label: "Name" },
  { id: "roles", alignment: "left", label: "Roles" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all groups" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Groups
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={onBulkDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

function Groups() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editingGroup, setEditingGroup] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    group: null,
  });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleClickOpen = () => {
    setEditingGroup(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingGroup(null);
  };

  const handleEdit = (group) => {
    setEditingGroup(group);
    setOpen(true);
  };

  const handleDelete = (group) => {
    setDeleteConfirmation({ open: true, group });
  };

  const handleBulkDelete = () => {
    setBulkDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_GROUP,
        variables: { id: deleteConfirmation.group.group.id },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setGroups(
        groups.filter((g) => g.group.id !== deleteConfirmation.group.group.id)
      );
      setAlert({
        open: true,
        message: "Group deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting group",
        severity: "error",
      });
    } finally {
      setDeleteConfirmation({ open: false, group: null });
    }
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: DELETE_GROUP,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);
      setGroups(groups.filter((group) => !selected.includes(group.group.id)));
      setSelected([]);
      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} groups`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: "Error deleting groups",
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = groups.map((n) => n.group.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getGroupsWithRoles {
                groupsWithRoles {
                  group {
                    id
                    name
                    createdAt
                  }
                  roles {
                    id
                    name
                  }
                }
              }
            }
          `,
        });

        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }

        // Sort by createdAt in descending order
        const sortedGroups =
          response.data.getGroupsWithRoles.groupsWithRoles.sort((a, b) => {
            return new Date(b.group.createdAt) - new Date(a.group.createdAt);
          });
        setGroups(sortedGroups);
      } catch (error) {
        setAlert({
          open: true,
          message: error.message || "Error fetching groups",
          severity: "error",
        });
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
          query {
            getRoles {
              id
              name
            }
          }
        `,
        });

        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }
        setRoles(response.data.getRoles);
      } catch (error) {
        setAlert({
          open: true,
          message: error.message || "Error fetching roles",
          severity: "error",
        });
      }
    };

    fetchGroups();
    fetchRoles();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <React.Fragment>
      <Helmet title="Groups" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Groups
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Settings
            </Link>
            <Typography>Groups</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            <AddIcon /> Add Group
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
        />
        <TableContainer
          sx={{
            maxHeight: "600px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#555",
              },
            },
          }}
        >
          <Table stickyHeader>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={groups.length}
            />
            <TableBody>
              {stableSort(groups, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.group.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.group.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, row.group.id)}
                        />
                      </TableCell>
                      <TableCell>{row.group.id}</TableCell>
                      <TableCell>{row.group.name}</TableCell>
                      <TableCell>
                        {row.roles.map((role) => role.name).join(", ")}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleEdit(row)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(row)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={groups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel": {
              // Target the "Rows per page" label
              marginBottom: "0",
              display: "flex",
              alignItems: "center",
            },
            ".MuiTablePagination-select": {
              // Target the select element
              marginRight: "32px",
              marginLeft: "8px",
            },
            ".MuiTablePagination-displayedRows": {
              // Target the rows count text
              marginBottom: "0",
            },
            ".MuiTablePagination-selectRoot": {
              // Target the select root
              marginRight: "8px",
            },
            ".MuiInputBase-root": {
              // Target the select input base
              marginRight: "8px",
              marginLeft: "8px",
              display: "flex",
              alignItems: "center",
            },
          }}
        />
      </Paper>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editingGroup ? "Edit Group" : "Create New Group"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editingGroup
              ? "Edit the group details below"
              : "Enter the details for the new group"}
          </DialogContentText>

          <Formik
            initialValues={{
              name: editingGroup ? editingGroup.group.name : "",
              roles: editingGroup
                ? editingGroup.roles.map((role) => role.id)
                : [],
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required("Name is required")
                .trim()
                .min(1, "Name cannot be empty"),
              roles: Yup.array().min(1, "At least one role is required"),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                const mutation = editingGroup
                  ? UPDATE_GROUP
                  : CREATE_GROUP_MUTATION;
                const variables = editingGroup
                  ? {
                      id: editingGroup.group.id,
                      input: {
                        name: values.name,
                        roleIds: values.roles,
                      },
                    }
                  : {
                      input: {
                        name: values.name,
                        roleIds: values.roles,
                      },
                    };

                const response = await axiosInstance.post("/graphql", {
                  query: mutation,
                  variables,
                });
                if (response.errors) {
                  console.log("!!!!!!!!!!!! crete grppppp YYY", response);
                  const errorMessage = response.errors[0].message;
                  if (errorMessage.includes("duplicate key error")) {
                    throw new Error(`Group "${values.name}" already exists`);
                  }
                  throw new Error(errorMessage);
                }

                const newGroup = editingGroup
                  ? response.data.updateGroup
                  : response.data.createGroup;

                setGroups((prevGroups) => {
                  if (editingGroup) {
                    return prevGroups.map((group) =>
                      group.group.id === editingGroup.group.id
                        ? newGroup
                        : group
                    );
                  }
                  return [newGroup, ...prevGroups];
                });

                setAlert({
                  open: true,
                  message: `Group successfully ${
                    editingGroup ? "updated" : "created"
                  }!`,
                  severity: "success",
                });
                handleClose();
              } catch (error) {
                setAlert({
                  open: true,
                  message: error.message,
                  severity: "error",
                });
                setErrors({ submit: error.message });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="text"
                  name="name"
                  label="Name"
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <div>
                  <Typography variant="subtitle1" gutterBottom>
                    Roles
                  </Typography>
                  {touched.roles && errors.roles && (
                    <FormHelperText error>{errors.roles}</FormHelperText>
                  )}
                  {roles.map((role) => (
                    <FormControlLabel
                      key={role.id}
                      control={
                        <Checkbox
                          checked={values.roles.includes(role.id)}
                          onChange={(event) => {
                            const selectedRoles = event.target.checked
                              ? [...values.roles, role.id]
                              : values.roles.filter((r) => r !== role.id);
                            handleChange({
                              target: {
                                name: "roles",
                                value: selectedRoles,
                              },
                            });
                          }}
                          name="roles"
                        />
                      }
                      label={role.name}
                    />
                  ))}
                </div>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {editingGroup ? "Save Changes" : "Create Group"}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, group: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the group "
            {deleteConfirmation.group?.group.name}"? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmation({ open: false, group: null })}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Delete Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected groups?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Groups
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Groups;
