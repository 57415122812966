import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { useUser } from "../../../contexts/UserContext";

const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const OPTIMAL_FILE_SIZE = 1 * 1024 * 1024; // 1MB

const compressImage = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions while maintaining aspect ratio
        if (width > height) {
          if (width > 800) {
            height = Math.round((height * 800) / width);
            width = 800;
          }
        } else {
          if (height > 800) {
            width = Math.round((width * 800) / height);
            height = 800;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // Convert to JPEG with quality adjustment
        const quality = 0.7; // Adjust quality to reduce file size
        const base64String = canvas.toDataURL("image/jpeg", quality);
        resolve(base64String);
      };
    };
  });
};

function Profile() {
  // const { user, isAuthenticated, isInitialized } = useAuth();
  const { user, updateUser } = useUser();
  const [loading, setLoading] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [avatarError, setAvatarError] = useState("");
  const [base64Image, setBase64Image] = useState(
    "/static/img/avatars/avatar-1.jpg"
  );

  // Fetch user data when component mounts
  useEffect(() => {
    if (user) {
      formik.setValues({
        name: user.name || "",
        email: user.email || "",
      });
      if (user.avatar) {
        setBase64Image(user.avatar);
      }
    }
  }, [user]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setAvatarError("");

    if (!file) return;

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      setAvatarError("File size should not exceed 10MB");
      return;
    }

    // Validate file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      setAvatarError("Please upload a valid image file (JPEG, PNG, or GIF)");
      return;
    }

    try {
      // Show loading state for large images
      if (file.size > OPTIMAL_FILE_SIZE) {
        setLoading(true);
      }

      // Compress image if it's larger than optimal size
      let imageData;
      if (file.size > OPTIMAL_FILE_SIZE) {
        imageData = await compressImage(file);
      } else {
        imageData = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      }

      setBase64Image(imageData);
    } catch (error) {
      console.error("Image processing error:", error);
      setAvatarError("Failed to process image. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "", // Preloaded from the backend
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Name is required")
        .min(1, "Name cannot be empty")
        .matches(/\S/, "Name cannot contain only spaces"),
    }),
    onSubmit: async (values) => {
      const trimmedValues = {
        ...values,
        name: values.name.trim(),
      };
      setLoading(true);
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
          mutation updateProfile($name: String!, $avatar: String) {
            updateProfile(name: $name, avatar: $avatar) {
              success
              message
            }
          }`,
          variables: {
            name: trimmedValues.name,
            avatar:
              base64Image === "/static/img/avatars/avatar-1.jpg"
                ? null
                : base64Image,
          },
        });

        const { success, message } = response.data.updateProfile;

        if (success) {
          // Update global user state
          updateUser({
            name: values.name,
            avatar: base64Image,
          });

          setNotification({
            open: true,
            message: "Profile updated successfully",
            severity: "success",
          });
        } else {
          throw new Error(message);
        }
      } catch (error) {
        console.error("Profile update error:", error);
        setNotification({
          open: true,
          message: error.message || "Failed to update profile",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const handleNotificationClose = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const handleClose = () => {
    setSnackBarMsg("");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={6}>
            <Grid item md={8}>
              <TextField
                name="name"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
                my={2}
              />
              <TextField
                name="email"
                label="Email ID"
                value={formik.values.email}
                disabled
                fullWidth
                my={2}
              />
            </Grid>
            <Grid item md={4}>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    width: "120px",
                    height: "120px",
                    margin: "0 auto 8px",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={base64Image}
                    alt="Profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="avatar-upload"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="avatar-upload">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUpload />}
                  >
                    Upload
                  </Button>
                </label>
                {avatarError && (
                  <Typography
                    color="error"
                    variant="caption"
                    display="block"
                    mt={1}
                  >
                    {avatarError}
                  </Typography>
                )}
                <Typography variant="caption" display="block" mt={1}>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3 }}
          >
            Save changes
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </form>

        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleNotificationClose}
            severity={notification.severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
}

export default Profile;
