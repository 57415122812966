import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../utils/axios";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import People from "../../pages/Events/People";
import EventEntry from "../../pages/Events/EventEntry";
import RegistrationForm from "../../pages/Events/RegistrationForm";
import Sessions from "../../pages/Events/Sessions";
import EventSpacesComponent from "../../components/Events/Events/EventSpaces/EventSpaces";
import BrandingComponent from "../../components/Events/Events/Branding/Branding";
import AddTypeDialog from "./AddType.js";
import { Snackbar, Alert } from "@mui/material";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Grid,
  Button,
  CardContent,
  Paper as MuiPaper,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  DialogActions,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
} from "@mui/material";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Share,
} from "@mui/icons-material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const ToolbarTitle = styled.div`
  min-width: 620px;
`;

const GET_EVENT_DATA = `
  query GetEventData($eventId: ID!) {
    getEvent(id: $eventId) {
      id
      name
      startDate
      endDate
      startTime
      endTime
      timezone
      organizedBy
      supportEmail
      about
    }
  }
`;

function EventHeader({ event }) {
  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleClickShowing = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseShowing = () => {
    setAnchorEl1(null);
  };

  if (!event) return null;

  const startDate = new Date(parseInt(event.startDate));
  const endDate = new Date(parseInt(event.endDate));
  const startTime = new Date(event.startTime);
  const endTime = new Date(event.endTime);

  const getEventStatus = (startDate, endDate) => {
    const now = new Date();
    if (now < startDate) return "Upcoming";
    if (now > endDate) return "Completed";
    return "Ongoing";
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Toolbar style={{ paddingLeft: "0" }}>
          <ToolbarTitle>
            <Typography
              variant="subtitle1"
              style={{ paddingLeft: "12px", fontWeight: "bold" }}
            >
              {event.name}
              <small
                style={{
                  fontWeight: "normal",
                  position: "relative",
                  bottom: "1px",
                  paddingLeft: "10px",
                  color: "rgb(244, 67, 54)",
                }}
              >
                {getEventStatus(startDate, endDate)}
              </small>
              <Typography
                color="success"
                style={{ paddingLeft: "12px !important" }}
                component="div"
              >
                <CalendarMonthOutlinedIcon fontSize="small" />
                <small
                  style={{
                    position: "relative",
                    bottom: "4px",
                    paddingLeft: "2px",
                  }}
                >
                  {startDate.toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                  ,{" "}
                  {startTime.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                    timeZone: event.timezone,
                  })}{" "}
                  -
                  {endDate.toLocaleDateString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                  ,{" "}
                  {endTime.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                    timeZone: event.timezone,
                  })}{" "}
                  • {event.about}
                </small>
              </Typography>
            </Typography>
          </ToolbarTitle>
          <Grid container display="block" textAlign="right">
            <Grid container>
              <Grid item md={12} className="filter">
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <Share />
                  Share Event
                </Button>
                <Button
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <VisibilityIcon />
                  View Event
                </Button>
                <Button
                  variant="contained"
                  sx={{ mr: 2, display: { xs: "none", md: "inline-flex" } }}
                >
                  <PlayArrowIcon />
                  Start Event
                </Button>
                <IconButton
                  aria-label="Edit"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <FilterAltIcon />
                </IconButton>
                <IconButton
                  aria-label="View"
                  size="large"
                  aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickShowing}
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2, display: { xs: "block", md: "none" } }}
                >
                  <VisibilityIcon />
                </IconButton>
                <Menu
                  id="simple-menu-showing"
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleCloseShowing}
                >
                  <MenuItem>All</MenuItem>
                  <MenuItem>Upcoming</MenuItem>
                  <MenuItem>Ongoing</MenuItem>
                  <MenuItem>Paused</MenuItem>
                  <MenuItem>Completed</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </CardContent>
    </Card>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RegistrationTab({ eventId }) {
  const [RTab, setRTab] = useState("Event Entry");

  return (
    <Grid container>
      <Grid item md={3}>
        <Paper>
          <MenuList style={{ height: "300px", padding: "0" }}>
            <MenuItem
              onClick={() => setRTab("Event Entry")}
              selected={RTab === "Event Entry"}
              style={{
                borderLeft: "3px solid",
                backgroundColor:
                  RTab === "Event Entry" ? "rgba(0, 0, 0, 0.04)" : "",
                minHeight: "50px",
              }}
            >
              <ListItemText>Event Entry</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => setRTab("Registration Form")}
              selected={RTab === "Registration Form"}
              style={{
                borderLeft: "3px solid",
                backgroundColor:
                  RTab === "Registration Form" ? "rgba(0, 0, 0, 0.04)" : "",
                minHeight: "50px",
              }}
            >
              <ListItemText>Registration Form</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Grid>
      <Grid item md={9} sx={{ paddingLeft: "25px" }}>
        {RTab === "Event Entry" ? (
          <EventEntry eventId={eventId} />
        ) : (
          <RegistrationForm eventId={eventId} />
        )}
      </Grid>
    </Grid>
  );
}

function PeopleTab({ eventId }) {
  const [selectedType, setSelectedType] = useState(null);
  const [types, setTypes] = useState([]);
  const [addTypeOpen, setAddTypeOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const showNotification = (message, severity = "success") => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const fetchTypes = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
          query GetEventTypes($eventId: ID!) {
            getEventAttendeeTypes(eventId: $eventId) {
              id
              name
            }
          }
        `,
        variables: { eventId },
      });

      if (response.data?.getEventAttendeeTypes) {
        setTypes(response.data.getEventAttendeeTypes);
      }
    } catch (error) {
      console.error("Error fetching types:", error);
    }
  };

  useEffect(() => {
    if (eventId) {
      fetchTypes();
    }
  }, [eventId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container>
      <Grid item md={3}>
        <Paper>
          <List component="nav">
            {types.map((type) => (
              <ListItem
                button
                key={type.id}
                selected={selectedType?.id === type.id}
                onClick={() => setSelectedType(type)}
              >
                <ListItemText primary={type.name} />
              </ListItem>
            ))}
          </List>
        </Paper>
        <Typography
          variant="body2"
          textAlign="right"
          style={{ paddingTop: "10px" }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setAddTypeOpen(true)}
          >
            <AddIcon /> Add Category
          </Button>
        </Typography>
      </Grid>
      <Grid item md={9} sx={{ paddingLeft: "25px" }}>
        {selectedType && (
          <People selectedType={selectedType} eventId={eventId} />
        )}
        {!selectedType && (
          <Paper sx={{ p: 4, textAlign: "center" }}>
            <Typography variant="h6" color="textSecondary">
              {types.length === 0
                ? "Please add a category to start managing attendees"
                : "Please select a category from the left"}
            </Typography>
          </Paper>
        )}
      </Grid>

      <AddTypeDialog
        open={addTypeOpen}
        handleClose={() => setAddTypeOpen(false)}
        eventId={eventId}
        onAddType={(newType) => {
          setTypes((prevTypes) => [...prevTypes, newType]);
          setSelectedType(newType);
          showNotification("Category added successfully");
        }}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

function SessionTab({ eventId }) {
  const [open, setOpen] = useState(false);
  return (
    <Grid container>
      <Grid item md={3}>
        <Paper>
          <MenuList style={{ height: "300px", padding: "0" }}>
            <MenuItem
              style={{
                borderLeft: "3px solid",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                minHeight: "50px",
              }}
            >
              <ListItemText>Session List</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Session Recordings</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Pre-recorded Videos</ListItemText>
            </MenuItem>
            <MenuItem style={{ minHeight: "50px" }}>
              <ListItemText>Session Settings</ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      </Grid>
      <Grid item md={9} sx={{ paddingLeft: "25px" }}>
        <Sessions eventId={eventId} />
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New People
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add people to the people list. For a seamless one-click entry
                for your people, please fill the form field.
              </DialogContentText>
              <br />
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <TextField
                    id="username"
                    label="People Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </Grid>
  );
}

export default function EventView() {
  const { id: eventId } = useParams();
  const [value, setValue] = useState(0);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: GET_EVENT_DATA,
          variables: { eventId },
        });

        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }

        setEventData(response.data.getEvent);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEventData();
    }
  }, [eventId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!eventData) return <div>No event data found</div>;

  return (
    <React.Fragment>
      <Helmet title={`Event View - ${eventData.name}`} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Event View
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/events/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/events/events">
              Events List
            </Link>
            <Typography component="div"> Event View</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/events/events"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <EventHeader event={eventData} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Registration" {...a11yProps(1)} />
            <Tab label="People" {...a11yProps(2)} />
            <Tab label="Sessions" {...a11yProps(3)} />
            <Tab label="Event Spaces" {...a11yProps(4)} />
            <Tab label="Branding" {...a11yProps(5)} />
            <Tab label="Integrations" {...a11yProps(6)} />
            <Tab label="Workflows" {...a11yProps(7)} />
            <Tab label="Analytics" {...a11yProps(8)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          Summary
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RegistrationTab eventId={eventId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <PeopleTab eventId={eventId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <SessionTab eventId={eventId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <EventSpacesComponent eventId={eventId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <BrandingComponent eventId={eventId} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          Integrations
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          Workflows
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          Analytics
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
}
