import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Drawer from "@mui/material/Drawer";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Button,
  Grid,
  Typography,
  DialogActions,
  TextField,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import axios from "axios";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  sessionDate: Yup.date().required("Required"),
  sessionTime: Yup.date().required("Required"), // Changed to date since it's a date object
  duration: Yup.number().required("Required"),
  description: Yup.string().required("Required"),
  hostIds: Yup.object().shape({
    // Changed to object for single host
    id: Yup.string().required("Host ID is required"),
  }),
  coHostIds: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
    })
  ),
  speakerIds: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
    })
  ),
  tracks: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
    })
  ),
  tags: Yup.array().of(Yup.string()),
});

function AttendeeSelector({
  eventId,
  type,
  label,
  value,
  onChange,
  multiple = false,
}) {
  const [attendees, setAttendees] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAttendeesForType = async () => {
      setLoading(true);
      try {
        // First get the type ID
        const typeResponse = await axios.post("/graphql", {
          query: `
            query GetEventTypes($eventId: ID!) {
              getEventAttendeeTypes(eventId: $eventId) {
                id
                name
              }
            }
          `,
          variables: { eventId },
        });

        const types = typeResponse.data?.data?.getEventAttendeeTypes || [];
        const typeId = types.find((t) => t.name === type)?.id;

        if (typeId) {
          // Then fetch attendees for this type
          const attendeesResponse = await axios.post("/graphql", {
            query: `
              query GetEventAttendees($eventId: ID!, $typeId: ID!) {
                getAllAttendees(eventId: $eventId, typeId: $typeId) {
                  id
                  firstName
                  lastName
                  email
                  jobTitle
                  organization
                }
              }
            `,
            variables: {
              eventId,
              typeId,
            },
          });

          const fetchedAttendees =
            attendeesResponse.data?.data?.getAllAttendees || [];
          setAttendees(fetchedAttendees);
        }
      } catch (error) {
        console.error(`Error fetching ${type} attendees:`, error);
        setAttendees([]);
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchAttendeesForType();
    }
  }, [eventId, type]);

  return (
    <Autocomplete
      multiple={multiple}
      options={attendees}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      getOptionLabel={(option) =>
        option
          ? `${option.firstName} ${option.lastName} (${
              option.organization || "No Organization"
            })`
          : ""
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={loading}
      renderOption={(props, option) => (
        <li {...props}>
          <div>
            <div>{`${option.firstName} ${option.lastName}`}</div>
            <div style={{ fontSize: "0.8em", color: "gray" }}>
              {option.jobTitle} at {option.organization}
            </div>
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          error={loading}
          helperText={loading ? "Loading..." : ""}
        />
      )}
    />
  );
}

function AvailableTracks({ eventId, onTrackSelect }) {
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const response = await axios.post("/graphql", {
          query: `
          query Tracks($eventId: ID!) {
            tracks(eventId: $eventId) {
              id
              name
            }
          }`,
          variables: {
            eventId: eventId,
          },
        });
        setTracks(response.data.data.tracks);
      } catch (error) {
        console.error("Error fetching tracks:", error);
        setTracks([]);
      }
    };

    if (eventId) {
      fetchTracks();
    }
  }, [eventId]);

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple
        options={tracks}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => onTrackSelect(newValue)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tracks"
            placeholder="Select Tracks"
            fullWidth
          />
        )}
      />
    </Grid>
  );
}

function SessionTags({ onTagChange }) {
  const [tags, setTags] = useState([]);

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple
        freeSolo
        options={tags}
        onChange={(event, newValue) => onTagChange(newValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={index} label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags"
            placeholder="Add Tags"
            fullWidth
          />
        )}
      />
    </Grid>
  );
}

function SessionTab({ eventId, onSessionAdded }) {
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      sessionDate: null,
      sessionTime: null,
      duration: "",
      description: "",
      hostIds: [],
      coHostIds: [],
      speakerIds: [],
      tracks: [],
      tags: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("@@@@@@@@@@@@@@@@@@@@ Current Form Errors:", formik.errors);
      console.log("@@@@@@@@@ Form submitted with values:", values);
      try {
        const sessionDate = values.sessionDate
          ? values.sessionDate.toISOString().split("T")[0]
          : null;
        const sessionTime = values.sessionTime
          ? values.sessionTime.toLocaleTimeString("en-US", {
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })
          : null;

        const response = await axios.post("/graphql", {
          query: `
          mutation CreateSession($input: CreateSessionInput!) {
            createSession(input: $input) {
              id
              name
            }
          }
        `,
          variables: {
            input: {
              eventId,
              name: values.name,
              sessionDate,
              sessionTime,
              duration: parseInt(values.duration, 10),
              description: values.description,
              hostIds: [values.hostIds.id],
              coHostIds: values.coHostIds.map((h) => h.id),
              speakerIds: values.speakerIds.map((s) => s.id),
              trackIds: values.tracks.map((t) => t.id),
              tags: values.tags,
            },
          },
        });

        if (response.data?.data?.createSession) {
          formik.resetForm();
          toggleDrawer("right", false)();
          onSessionAdded?.();
        }
      } catch (error) {
        console.error("Error creating session:", error);
      }
    },
  });

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={toggleDrawer("right", true)}
        style={{ marginRight: "1rem" }}
      >
        <AddIcon />
        Session
      </Button>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box sx={{ width: "600px", padding: "20px" }} role="presentation">
          <Typography variant="h3" gutterBottom>
            Add Session
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item xs={4}>
                <DatePicker
                  label="Session Date"
                  value={formik.values.sessionDate}
                  onChange={(value) => {
                    formik.setFieldValue("sessionDate", value);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error:
                        formik.touched.sessionDate &&
                        Boolean(formik.errors.sessionDate),
                      helperText:
                        formik.touched.sessionDate && formik.errors.sessionDate,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TimePicker
                  label="Session Time"
                  value={formik.values.sessionTime}
                  onChange={(value) => {
                    formik.setFieldValue("sessionTime", value);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error:
                        formik.touched.sessionTime &&
                        Boolean(formik.errors.sessionTime),
                      helperText:
                        formik.touched.sessionTime && formik.errors.sessionTime,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="duration"
                  name="duration"
                  label="Duration (Minutes)"
                  fullWidth
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.duration && Boolean(formik.errors.duration)
                  }
                  helperText={formik.touched.duration && formik.errors.duration}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <AttendeeSelector
                  eventId={eventId}
                  type="Host"
                  label="Select Host"
                  value={formik.values.hostIds}
                  onChange={(newValue) =>
                    formik.setFieldValue("hostIds", newValue)
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <AttendeeSelector
                  eventId={eventId}
                  type="Co Host"
                  label="Select Co-Hosts"
                  value={formik.values.coHostIds}
                  onChange={(newValue) =>
                    formik.setFieldValue("coHostIds", newValue)
                  }
                  multiple
                />
              </Grid>

              <Grid item xs={12}>
                <AttendeeSelector
                  eventId={eventId}
                  type="Speaker"
                  label="Select Speakers"
                  value={formik.values.speakerIds}
                  onChange={(newValue) =>
                    formik.setFieldValue("speakerIds", newValue)
                  }
                  multiple
                />
              </Grid>

              <AvailableTracks
                eventId={eventId}
                onTrackSelect={(tracks) =>
                  formik.setFieldValue("tracks", tracks)
                }
              />

              <SessionTags
                onTagChange={(tags) => formik.setFieldValue("tags", tags)}
              />
            </Grid>

            <DialogActions>
              <Button onClick={toggleDrawer("right", false)} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => console.log("Button clicked")}
              >
                Done
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

function Session({ eventId, onSessionAdded }) {
  return (
    <React.Fragment>
      <SessionTab eventId={eventId} onSessionAdded={onSessionAdded} />
    </React.Fragment>
  );
}

export default Session;
