import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import styled from "@emotion/styled";

const TrackContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  align-items: center;
`;

const TrackDetails = styled(Box)`
  text-align: left;
`;

const TrackActions = styled(Box)`
  display: flex;
  align-items: center;
`;

const TrackTab = ({ eventId: propEventId }) => {
  const params = useParams();
  const eventId =
    propEventId ||
    params.eventId ||
    window.location.pathname.split("/view-event/")[1];
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    console.log("@@@@@@@@@@@@@@@@@@@@ EventId received:", eventId);
    fetchTracks();
  }, [eventId]);

  const fetchTracks = async () => {
    try {
      const response = await axios.post("/graphql", {
        query: `
          query Tracks($eventId: ID!) {
            tracks(eventId: $eventId) {
              id
              name
              description
            }
          }
        `,
        variables: {
          eventId: eventId,
        },
      });

      console.log("Tracks response:", response.data);
      const fetchedTracks = response.data.data?.tracks || []; // Changed from getTracks to tracks
      setTracks(fetchedTracks);
    } catch (error) {
      console.error("Error fetching tracks", error);
      setTracks([]);
    }
  };

  const formik = useFormik({
    initialValues: {
      trackName: "",
      description: "",
    },
    validationSchema: Yup.object({
      trackName: Yup.string()
        .max(50, "Track name cannot be longer than 50 characters")
        .required("Track name is required"),
      description: Yup.string()
        .max(200, "Description cannot be longer than 200 characters")
        .required("Description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post("/graphql", {
          query: `
            mutation AddTrack($input: AddTrackInput!) {
              addTrack(input: $input) {
                id
                name
                description
              }
            }
          `,
          variables: {
            input: {
              name: values.trackName,
              description: values.description,
              eventId: eventId,
            },
          },
        });

        console.log("Add track response:", response.data);
        resetForm();
        fetchTracks();
      } catch (error) {
        console.error("Error adding track:", error.response?.data || error);
      }
    },
  });

  return (
    <Box sx={{ p: 3 }}>
      {/* Form Section */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Add Track
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="trackName"
              name="trackName"
              label="Track Name"
              value={formik.values.trackName}
              onChange={formik.handleChange}
              error={
                formik.touched.trackName && Boolean(formik.errors.trackName)
              }
              helperText={formik.touched.trackName && formik.errors.trackName}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              multiline
              rows={4}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save Track
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Tracks List Section */}
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Existing Tracks
        </Typography>
        {tracks.length === 0 ? (
          <Typography
            variant="body2"
            style={{ textAlign: "center", padding: "20px" }}
          >
            No tracks available yet
          </Typography>
        ) : (
          tracks.map((track) => (
            <TrackContainer key={track.id}>
              <TrackDetails>
                <Typography variant="h6">{track.name}</Typography>
                <Typography variant="body2">{track.description}</Typography>
              </TrackDetails>
              <TrackActions>
                <IconButton color="primary" size="small">
                  <EditIcon />
                </IconButton>
                <IconButton color="error" size="small">
                  <DeleteIcon />
                </IconButton>
              </TrackActions>
            </TrackContainer>
          ))
        )}
      </Box>
    </Box>
  );
};

export default TrackTab;
