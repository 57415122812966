import React from "react";
import Editor from "@react-page/editor";
import "@react-page/editor/lib/index.css";

import slate from "@react-page/plugins-slate";
const slatePlugin = slate();

const DEMO_CONTENT = {
  id: "demo-template",
  version: 1,
  rows: [
    {
      cells: [
        {
          id: "header",
          size: 12,
          content: {
            plugin: slatePlugin,
            state: {
              slate: [
                {
                  type: "h1",
                  children: [{ text: "👋 Welcome to React-Page Editor" }],
                },
                {
                  type: "paragraph",
                  children: [
                    {
                      text: "This is a powerful WYSIWYG editor for React. Start editing this text to see how it works!",
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      cells: [
        {
          id: "features-left",
          size: 6,
          content: {
            plugin: slatePlugin,
            state: {
              slate: [
                {
                  type: "h2",
                  children: [{ text: "✨ Rich Text Editing" }],
                },
                {
                  type: "paragraph",
                  children: [
                    {
                      text: "Format text with bold, italic, underline, and more. Create lists, headings, and other rich content.",
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          id: "features-right",
          size: 6,
          content: {
            plugin: slatePlugin,
            state: {
              slate: [
                {
                  type: "h2",
                  children: [{ text: "🎨 Customizable Layout" }],
                },
                {
                  type: "paragraph",
                  children: [
                    {
                      text: "Create complex layouts with columns and nested rows. Drag and drop cells to reorganize content.",
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
    {
      cells: [
        {
          id: "content-main",
          size: 8,
          content: {
            plugin: slatePlugin,
            state: {
              slate: [
                {
                  type: "h2",
                  children: [{ text: "📝 Main Content Area" }],
                },
                {
                  type: "paragraph",
                  children: [
                    {
                      text: "This is your main content area. You can add multiple paragraphs, lists, and other elements here.",
                    },
                  ],
                },
                {
                  type: "ul",
                  children: [
                    {
                      type: "li",
                      children: [
                        { text: "Supports markdown-style formatting" },
                      ],
                    },
                    {
                      type: "li",
                      children: [{ text: "Drag and drop interface" }],
                    },
                    {
                      type: "li",
                      children: [{ text: "Customizable plugins" }],
                    },
                  ],
                },
              ],
            },
          },
        },
        {
          id: "content-sidebar",
          size: 4,
          content: {
            plugin: slatePlugin,
            state: {
              slate: [
                {
                  type: "h3",
                  children: [{ text: "📌 Sidebar Content" }],
                },
                {
                  type: "paragraph",
                  children: [
                    {
                      text: "This could be used for additional information, navigation, or other sidebar content.",
                    },
                  ],
                },
              ],
            },
          },
        },
      ],
    },
  ],
};

export default function EditorPage() {
  const [value, setValue] = React.useState(DEMO_CONTENT);

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <Editor cellPlugins={[slatePlugin]} value={value} onChange={setValue} />
    </div>
  );
}
