// filename: components/sidebar/dashboardItems.js

import React, { useEffect, useState } from "react";
import { Sliders } from "react-feather";
import { axiosInstance } from "../../utils/axios";

const GET_SPACES = `
  query {
    getSpaces {
      id
      name
    }
  }
`;

const useDashboardItems = () => {
  const [spaces, setSpaces] = useState([]);

  useEffect(() => {
    const fetchSpaces = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: GET_SPACES,
        });

        if (response.data.errors) {
          console.error("Error fetching spaces:", response.data.errors);
          return;
        }

        const fetchedSpaces = response.data.getSpaces || [];
        const spacePages = fetchedSpaces.map((space) => ({
          href: `/spaces/${space.id}`, // You can modify this path as needed
          icon: Sliders,
          title: space.name,
        }));

        setSpaces(spacePages);
      } catch (error) {
        console.error("Error fetching spaces:", error);
      }
    };

    fetchSpaces();
  }, []);

  const navItems = [
    {
      title: "Spaces",
      pages: spaces,
    },
  ];

  return navItems;
};

export default useDashboardItems;
