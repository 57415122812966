// AddType.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../utils/axios";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
});

function AddTypeDialog({ open, handleClose, eventId, onAddType }) {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
          mutation CreateAttendeeType($input: CreateAttendeeTypeInput!) {
            createAttendeeType(input: $input) {
              id
              name
            }
          }
        `,
        variables: {
          input: {
            name: values.name,
            eventId,
          },
        },
      });

      if (response.data?.createAttendeeType) {
        const newType = response.data.createAttendeeType;
        onAddType(newType);
        resetForm();
        handleClose();
      }
    } catch (error) {
      console.error("Error creating type:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Category</DialogTitle>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <DialogContent>
              <Field
                name="name"
                as={TextField}
                label="Category Name"
                fullWidth
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" disabled={isSubmitting} color="primary">
                Add
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AddTypeDialog;
