import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosInstance } from "../../utils/axios";

import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Checkbox,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const GET_SPACES = `
  query {
    getSpaces {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const CREATE_SPACE = `
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_SPACE = `
  mutation UpdateSpace($id: ID!, $name: String!) {
    updateSpace(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const DELETE_SPACE = `
  mutation DeleteSpace($id: ID!) {
    deleteSpace(id: $id)
  }
`;

const formatDate = (dateString) => {
  if (!dateString) return "";
  try {
    const date = new Date(parseInt(dateString));
    if (isNaN(date.getTime())) {
      const isoDate = new Date(dateString);
      if (isNaN(isoDate.getTime())) return "Invalid Date";
      return new Intl.DateTimeFormat("default", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(isoDate);
    }
    return new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date";
  }
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const headCells = [
    { id: "id", align: "left", label: "ID" },
    { id: "name", align: "left", label: "Name" },
    { id: "createdAt", align: "left", label: "Created At" },
    { id: "actions", align: "right", label: "Actions" },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all spaces" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete, onFilterClick } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Spaces
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={onBulkDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton onClick={onFilterClick}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

function Spaces() {
  const [spaces, setSpaces] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingSpace, setEditingSpace] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    spaceId: null,
    spaceName: null,
  });
  const [bulkDeleteConfirmation, setBulkDeleteConfirmation] = useState({
    open: false,
    count: 0,
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchSpaces();
  }, []);

  const fetchSpaces = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: GET_SPACES,
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      // setSpaces(response.data.getSpaces);
      const sortedSpaces = response.data.getSpaces.sort((a, b) => {
        const dateA = new Date(parseInt(a.createdAt));
        const dateB = new Date(parseInt(b.createdAt));
        return dateB - dateA;
      });

      setSpaces(sortedSpaces);
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error fetching spaces",
        severity: "error",
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = spaces.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (space) => {
    setEditingSpace(space);
    setOpenDialog(true);
  };

  const handleDeleteClick = (space) => {
    setDeleteConfirmation({
      open: true,
      spaceId: space.id,
      spaceName: space.name,
    });
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation({
      open: false,
      spaceId: null,
      spaceName: null,
    });
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_SPACE,
        variables: { id: deleteConfirmation.spaceId },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setSpaces(
        spaces.filter((space) => space.id !== deleteConfirmation.spaceId)
      );
      setAlert({
        open: true,
        message: "Space deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting space",
        severity: "error",
      });
    } finally {
      handleCancelDelete();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingSpace(null);
  };

  const handleBulkDeleteClick = () => {
    setBulkDeleteConfirmation({
      open: true,
      count: selected.length,
    });
  };

  const handleCancelBulkDelete = () => {
    setBulkDeleteConfirmation({
      open: false,
      count: 0,
    });
  };

  const handleConfirmBulkDelete = async () => {
    try {
      // Create an array of promises for each delete operation
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: DELETE_SPACE,
          variables: { id },
        })
      );

      // Wait for all deletes to complete
      await Promise.all(deletePromises);

      // Update the spaces list by filtering out deleted spaces
      setSpaces(spaces.filter((space) => !selected.includes(space.id)));

      // Clear the selected items
      setSelected([]);

      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} spaces`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: "Error deleting spaces. Please try again.",
        severity: "error",
      });
    } finally {
      handleCancelBulkDelete();
    }
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const mutation = editingSpace ? UPDATE_SPACE : CREATE_SPACE;
      const variables = editingSpace
        ? { id: editingSpace.id, name: values.name }
        : { input: { name: values.name } };

      const response = await axiosInstance.post("/graphql", {
        query: mutation,
        variables,
      });

      console.log("!!!!!!!!!!!!!!!!!! errrrr", response);

      if (response.errors) {
        const errorMessage = response.errors[0].message;
        throw new Error(errorMessage);
        return;
      }

      const newSpace = editingSpace
        ? response.data.updateSpace
        : response.data.createSpace;

      setSpaces((prev) =>
        editingSpace
          ? prev.map((space) =>
              space.id === editingSpace.id ? newSpace : space
            )
          : [...prev, newSpace]
      );

      setAlert({
        open: true,
        message: `Space ${editingSpace ? "updated" : "created"} successfully`,
        severity: "success",
      });

      handleCloseDialog();
    } catch (error) {
      setAlert({
        open: true,
        message:
          error.message ||
          `Error ${editingSpace ? "updating" : "creating"} space`,
        severity: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, spaces.length - page * rowsPerPage);

  return (
    <React.Fragment>
      <Helmet title="Spaces" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Spaces
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Spaces</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            <AddIcon /> Add Space
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDeleteClick}
        />
        <TableContainer
          sx={{
            maxHeight: "600px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#555",
              },
            },
          }}
        >
          <Table stickyHeader>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={spaces.length}
            />
            <TableBody>
              {stableSort(spaces, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((space, index) => {
                  const isItemSelected = isSelected(space.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={space.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, space.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell>{space.id}</TableCell>
                      <TableCell>{space.name}</TableCell>
                      <TableCell>{formatDate(space.createdAt)}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          onClick={() => handleEditClick(space)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteClick(space)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={spaces.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel": {
              // Target the "Rows per page" label
              marginBottom: "0",
              display: "flex",
              alignItems: "center",
            },
            ".MuiTablePagination-select": {
              // Target the select element
              marginRight: "32px",
              marginLeft: "8px",
            },
            ".MuiTablePagination-displayedRows": {
              // Target the rows count text
              marginBottom: "0",
            },
            ".MuiTablePagination-selectRoot": {
              // Target the select root
              marginRight: "8px",
            },
            ".MuiInputBase-root": {
              // Target the select input base
              marginRight: "8px",
              marginLeft: "8px",
              display: "flex",
              alignItems: "center",
            },
          }}
        />
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {editingSpace ? "Edit Space" : "Create New Space"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editingSpace
              ? "Edit the space details below"
              : "Enter the details for the new space"}
          </DialogContentText>
          <Formik
            initialValues={{
              name: editingSpace ? editingSpace.name : "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required").trim(),
            })}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <MuiTextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  {isSubmitting
                    ? "Saving..."
                    : editingSpace
                    ? "Save Changes"
                    : "Create Space"}
                </Button>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteConfirmation.open}
        onClose={handleCancelDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the space "
            {deleteConfirmation.spaceName}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bulkDeleteConfirmation.open}
        onClose={handleCancelBulkDelete}
        aria-labelledby="bulk-delete-dialog-title"
        aria-describedby="bulk-delete-dialog-description"
      >
        <DialogTitle id="bulk-delete-dialog-title">
          Confirm Bulk Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="bulk-delete-dialog-description">
            Are you sure you want to delete {bulkDeleteConfirmation.count}{" "}
            selected spaces? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelBulkDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmBulkDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete {bulkDeleteConfirmation.count} Spaces
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default Spaces;
