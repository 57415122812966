import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import DialogActions from "@mui/material/DialogActions";
import { axiosInstance } from "../../utils/axios";

import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  MenuItem,
  Checkbox,
  Select,
  InputLabel,
  FormControl,
  ListItemText,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { green, orange, red } from "@mui/material/colors";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.shipped && green[500]};
  background: ${(props) => props.processing && orange[700]};
  background: ${(props) => props.cancelled && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "name", alignment: "left", label: "Name" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "", alignment: "left", label: "Action" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected, onBulkDelete } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Users List
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large" onClick={onBulkDelete}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

function EnhancedTable({
  users,
  setUsers,
  handleEditClick,
  handleDeleteClick,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rows = users;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer
          sx={{
            maxHeight: "600px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#555",
              },
            },
          }}
        >
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {stableSort(users, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>

                      <TableCell align="id">#{row.id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        {/* Edit Button */}
                        <IconButton
                          aria-label="edit"
                          color="secondary"
                          onClick={() => handleEditClick(row)}
                        >
                          <EditIcon />
                        </IconButton>

                        {/* Delete Button */}
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleDeleteClick(row)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".MuiTablePagination-selectLabel": {
              // Target the "Rows per page" label
              marginBottom: "0",
              display: "flex",
              alignItems: "center",
            },
            ".MuiTablePagination-select": {
              // Target the select element
              marginRight: "32px",
              marginLeft: "8px",
            },
            ".MuiTablePagination-displayedRows": {
              // Target the rows count text
              marginBottom: "0",
            },
            ".MuiTablePagination-selectRoot": {
              // Target the select root
              marginRight: "8px",
            },
            ".MuiInputBase-root": {
              // Target the select input base
              marginRight: "8px",
              marginLeft: "8px",
              display: "flex",
              alignItems: "center",
            },
          }}
        />
      </Paper>
    </div>
  );
}

function Users() {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, user: null });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [selected, setSelected] = useState([]);

  // Add delete handlers
  const handleDelete = (user) => {
    setDeleteDialog({ open: true, user });
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation DeleteUser($id: ID!) {
          deleteUser(id: $id)
        }
      `,
        variables: { id: deleteDialog.user.id },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setUsers((prev) =>
        prev.filter((user) => user.id !== deleteDialog.user.id)
      );
      setAlert({
        open: true,
        message: "User deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting user",
        severity: "error",
      });
    } finally {
      setDeleteDialog({ open: false, user: null });
    }
  };

  const handleBulkDelete = () => {
    setBulkDeleteDialog(true);
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: `
          mutation DeleteUser($id: ID!) {
            deleteUser(id: $id)
          }
        `,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);
      setUsers((prev) => prev.filter((user) => !selected.includes(user.id)));
      setSelected([]);
      setAlert({
        open: true,
        message: `Successfully deleted ${selected.length} users`,
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: "Error deleting users",
        severity: "error",
      });
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (user) => {
    console.log("!!!!!!!!!!!! edit User", user);
    setEditingUser(user);
    setOpen(true); // Open the form to edit the user
  };

  const handleDeleteClick = async (userId) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
          mutation DeleteUser($id: ID!) {
            deleteUser(id: $id) {
              id
            }
          }
        `,
        variables: { id: userId },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      setAlert({
        open: true,
        message: "User Deleted Successfully!",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "An unexpected error occurred",
        severity: "error",
      });
    }
  };

  const UPDATE_USER_MUTATION = `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        name
        email
        roles {
          id
          name
        }
        groups {
          id
          name
        }
      }
    }
  }
`;

  const handleUpdateUser = async (values) => {
    try {
      const { name, email, selectedRoles, selectedGroups } = values;
      const response = await axiosInstance.post("/graphql", {
        query: UPDATE_USER_MUTATION,
        variables: {
          input: {
            id: editingUser.id,
            name,
            email,
            roles: selectedRoles,
            groups: selectedGroups,
          },
        },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      const updatedUser = response.data.updateUser.user;
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updatedUser.id ? updatedUser : user
        )
      );

      setAlert({
        open: true,
        message: "User Updated Successfully!",
        severity: "success",
      });
      handleClose(); // Close the dialog after successful update
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "An unexpected error occurred",
        severity: "error",
      });
    }
  };

  const REGISTER_USER_MUTATION = `
  mutation RegisterUser($input: UserInput!) {
    registerUser(input: $input) {
      user {
        id
        name
        email
      }
    }
  }
`;

  // Fetch users initially
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getUsers {
                id
                name
                email
              roles {
                  id
                  name
                }
              groups {
                  id
                  name
                }
              }
            }
          `,
        });

        if (response.data.errors) {
          console.error(response.data.errors);
        } else {
          console.log("!!!!!!!!!! getUsers", response.data.getUsers);
          const sortedUsers = response.data.getUsers.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });

          setUsers(sortedUsers);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getRoles {
                id
                name
              }
            }
          `,
        });
        setRoles(response.data.getRoles || []);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    const fetchGroups = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getGroups {
                id
                name
              }
            }
          `,
        });
        setGroups(response.data.getGroups || []);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchUsers();
    fetchRoles();
    fetchGroups();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Users" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Users
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Settings
            </Link>
            <Typography>Users</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              <AddIcon />
              Add User
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>
                {editingUser ? "Edit User" : "Add User"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To add a new user, please fill in the details below.
                </DialogContentText>
                <Formik
                  initialValues={{
                    name: editingUser ? editingUser.name : "",
                    email: editingUser ? editingUser.email : "",
                    password: "", // Keep this empty for both new and editing users
                    selectedRoles: editingUser
                      ? editingUser.roles.map((role) => role.id)
                      : [],
                    selectedGroups: editingUser
                      ? editingUser.groups.map((group) => group.id)
                      : [],
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .trim()
                      .required("Name is required")
                      .min(1, "Name cannot be empty")
                      .matches(/\S/, "Name cannot be only whitespace")
                      .max(255, "Name is too long"),
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                    password: editingUser
                      ? Yup.string()
                          .min(12, "Must be at least 12 characters")
                          .max(255)
                      : Yup.string()
                          .min(12, "Must be at least 12 characters")
                          .max(255)
                          .required("Password is required"),
                    selectedRoles: Yup.array().min(
                      1,
                      "At least one role must be selected"
                    ),
                    selectedGroups: Yup.array().min(
                      1,
                      "At least one group must be selected"
                    ),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting, resetForm }
                  ) => {
                    try {
                      const {
                        name,
                        email,
                        password,
                        selectedRoles,
                        selectedGroups,
                      } = values;

                      if (editingUser) {
                        // Only include password in the update if it's not empty
                        const updateData = {
                          name,
                          email,
                          roles: selectedRoles,
                          groups: selectedGroups,
                        };
                        if (password) {
                          updateData.password = password;
                        }
                        console.log("!!!!!!!!!!!! update user @@@");
                        await handleUpdateUser(updateData);
                      } else {
                        // For new user, include all fields including password
                        const response = await axiosInstance.post("/graphql", {
                          query: REGISTER_USER_MUTATION,
                          variables: {
                            input: {
                              name,
                              email,
                              password,
                              roles: selectedRoles,
                              groups: selectedGroups,
                            },
                          },
                        });
                        if (response.errors) {
                          const errorMessage = response.errors[0].message;
                          setAlert({
                            open: true,
                            message: errorMessage,
                            severity: "error",
                          });
                          throw new Error(response.errors[0].message);
                        }

                        const newUser = response.data.registerUser.user;

                        // Update the users list with the new user
                        setUsers((prevUsers) => [...prevUsers, newUser]);

                        // Show success message
                        setAlert({
                          open: true,
                          message: "User added successfully!",
                          severity: "success",
                        });

                        // Close the dialog
                        handleClose();

                        // Reset the form
                        resetForm();
                        // ... rest of your code for handling the response
                      }
                      // ... rest of your success handling code
                    } catch (error) {
                      // ... your error handling code
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      {errors.submit && (
                        <Alert severity="warning">{errors.submit}</Alert>
                      )}
                      <MuiTextField
                        type="text"
                        name="name"
                        label="Name"
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                        mb={5}
                      />
                      <div style={{ marginBottom: 15 }}></div>
                      <MuiTextField
                        type="email"
                        name="email"
                        label="Email address"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                      />
                      <div style={{ marginBottom: 15 }}></div>
                      <MuiTextField
                        type="password"
                        name="password"
                        label="Password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                      />
                      <div style={{ marginBottom: 15 }}></div>
                      <FormControl fullWidth my={3}>
                        <InputLabel>Roles</InputLabel>
                        <Select
                          multiple
                          name="selectedRoles"
                          value={values.selectedRoles}
                          onChange={(e) =>
                            setFieldValue("selectedRoles", e.target.value)
                          }
                          renderValue={(selected) => (
                            <div>
                              {selected.map((value) => (
                                <Chip
                                  sx={{ bgcolor: "primary.main" }}
                                  key={value}
                                  label={
                                    roles.find((role) => role.id === value)
                                      ?.name || value
                                  }
                                />
                              ))}
                            </div>
                          )}
                        >
                          {roles.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                              <Checkbox
                                checked={
                                  values.selectedRoles.indexOf(role.id) > -1
                                }
                              />
                              <ListItemText primary={role.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div style={{ marginBottom: 15 }}></div>
                      <FormControl fullWidth my={3}>
                        <InputLabel>Groups</InputLabel>
                        <Select
                          multiple
                          name="selectedGroups"
                          value={values.selectedGroups}
                          onChange={(e) =>
                            setFieldValue("selectedGroups", e.target.value)
                          }
                          renderValue={(selected) => (
                            <div>
                              {selected.map((value) => (
                                <Chip
                                  sx={{ bgcolor: "primary.main" }}
                                  key={value}
                                  label={
                                    groups.find((group) => group.id === value)
                                      ?.name || value
                                  }
                                />
                              ))}
                            </div>
                          )}
                        >
                          {groups.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                              <Checkbox
                                checked={
                                  values.selectedGroups.indexOf(group.id) > -1
                                }
                              />
                              <ListItemText primary={group.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div style={{ marginBottom: 15 }}></div>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        {editingUser ? "Edit User" : "Add User"}
                      </Button>
                    </form>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>
            <Dialog
              open={deleteDialog.open}
              onClose={() => setDeleteDialog({ open: false, user: null })}
            >
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete the user "
                  {deleteDialog.user?.name}"? This action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setDeleteDialog({ open: false, user: null })}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleDeleteConfirm}
                  color="error"
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={bulkDeleteDialog}
              onClose={() => setBulkDeleteDialog(false)}
            >
              <DialogTitle>Confirm Bulk Delete</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete {selected.length} selected
                  users? This action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setBulkDeleteDialog(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={handleBulkDeleteConfirm}
                  color="error"
                  variant="contained"
                >
                  Delete {selected.length} Users
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable
            users={users}
            setUsers={setUsers}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDelete}
            selected={selected}
            setSelected={setSelected}
            onBulkDelete={handleBulkDelete}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Users;
