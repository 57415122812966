import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  FormControlLabel,
  TextField,
  FormHelperText,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { axiosInstance } from "../../utils/axios";

// Helper functions for sorting
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const PermissionsGroup = styled(Box)({
  marginBottom: "1rem",
  padding: "0.5rem",
  border: "1px solid #ddd",
  borderRadius: "4px",
  backgroundColor: "#f9f9f9",
});

const UPDATE_ROLE = `
  mutation UpdateRole($id: ID!, $input: UpdateRoleInput!) {
    updateRole(id: $id, input: $input) {
      id
      name
      permissions
    }
  }
`;

const DELETE_ROLE = `
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id)
  }
`;

function EnhancedTableToolbar(props) {
  const { numSelected, onBulkDelete } = props;

  return (
    <Toolbar>
      <div>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Roles
          </Typography>
        )}
      </div>
      <Spacer />
      <div>
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={onBulkDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

function RolesList() {
  const [roles, setRoles] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [editDialog, setEditDialog] = useState({ open: false, role: null });
  const [deleteDialog, setDeleteDialog] = useState({ open: false, role: null });
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchRolesAndPermissions();
  }, []);

  const fetchRolesAndPermissions = async () => {
    try {
      // Fetch roles
      const rolesResponse = await axiosInstance.post("/graphql", {
        query: `
          query {
            getRoles {
              id
              name
              permissions
            }
          }
        `,
      });

      // Fetch permissions
      const permissionsResponse = await axiosInstance.post("/graphql", {
        query: `
          query {
            getPermissions {
              id
              name
              childrens
            }
          }
        `,
      });

      if (rolesResponse.data.errors) {
        throw new Error(rolesResponse.data.errors[0].message);
      }

      if (permissionsResponse.data.errors) {
        throw new Error(permissionsResponse.data.errors[0].message);
      }

      // setRoles(rolesResponse.data.getRoles);
      // setPermissionsData(permissionsResponse.data.getPermissions);
      const sortedRoles = rolesResponse.data.getRoles.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setRoles(sortedRoles);
      setPermissionsData(permissionsResponse.data.getPermissions);
    } catch (error) {
      showAlert(error.message, "error");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roles.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleEdit = (role) => {
    setEditDialog({ open: true, role });
  };

  const handleDelete = (role) => {
    setDeleteDialog({ open: true, role });
  };

  const handleBulkDelete = () => {
    setBulkDeleteDialog(true);
  };

  const handleEditSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: UPDATE_ROLE,
        variables: {
          id: editDialog.role.id,
          input: {
            name: values.roleName,
            permissions: Object.entries(values.permissions)
              .filter(([, value]) => value)
              .map(([key]) => key),
          },
        },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setRoles((prev) =>
        prev.map((role) =>
          role.id === editDialog.role.id ? response.data.updateRole : role
        )
      );
      showAlert("Role updated successfully", "success");
      setEditDialog({ open: false, role: null });
    } catch (error) {
      showAlert(error.message, "error");
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_ROLE,
        variables: { id: deleteDialog.role.id },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setRoles((prev) =>
        prev.filter((role) => role.id !== deleteDialog.role.id)
      );
      showAlert("Role deleted successfully", "success");
      setSelected((prev) => prev.filter((id) => id !== deleteDialog.role.id));
    } catch (error) {
      showAlert(error.message, "error");
    } finally {
      setDeleteDialog({ open: false, role: null });
    }
  };

  const handleBulkDeleteConfirm = async () => {
    try {
      const deletePromises = selected.map((id) =>
        axiosInstance.post("/graphql", {
          query: DELETE_ROLE,
          variables: { id },
        })
      );

      await Promise.all(deletePromises);
      setRoles((prev) => prev.filter((role) => !selected.includes(role.id)));
      showAlert(`Successfully deleted ${selected.length} roles`, "success");
      setSelected([]);
    } catch (error) {
      showAlert("Error deleting roles", "error");
    } finally {
      setBulkDeleteDialog(false);
    }
  };

  const showAlert = (message, severity = "success") => {
    setAlert({ open: true, message, severity });
  };

  // Table headers
  const headCells = [
    { id: "id", label: "ID", align: "left" },
    { id: "name", label: "Name", align: "left" },
    { id: "permissions", label: "Permissions", align: "left" },
    { id: "actions", label: "Actions", align: "right" },
  ];

  return (
    <React.Fragment>
      <Helmet title="Roles" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Roles
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Roles</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            component={NavLink}
            to="/settings/roles-permissions-add"
            variant="contained"
            color="primary"
          >
            <AddIcon /> New Role
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onBulkDelete={handleBulkDelete}
        />
        <TableContainer
          sx={{
            maxHeight: "600px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#555",
              },
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < roles.length
                    }
                    checked={
                      roles.length > 0 && selected.length === roles.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(roles, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((role) => {
                  const isItemSelected = selected.includes(role.id);

                  return (
                    <TableRow
                      hover
                      key={role.id}
                      selected={isItemSelected}
                      role="checkbox"
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, role.id)}
                        />
                      </TableCell>
                      <TableCell>{role.id}</TableCell>
                      <TableCell>{role.name}</TableCell>
                      <TableCell>{role.permissions.join(", ")}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleEdit(role)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDelete(role)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={roles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          sx={{
            ".MuiTablePagination-selectLabel": {
              // Target the "Rows per page" label
              marginBottom: "0",
              display: "flex",
              alignItems: "center",
            },
            ".MuiTablePagination-select": {
              // Target the select element
              marginRight: "32px",
              marginLeft: "8px",
            },
            ".MuiTablePagination-displayedRows": {
              // Target the rows count text
              marginBottom: "0",
            },
            ".MuiTablePagination-selectRoot": {
              // Target the select root
              marginRight: "8px",
            },
            ".MuiInputBase-root": {
              // Target the select input base
              marginRight: "8px",
              marginLeft: "8px",
              display: "flex",
              alignItems: "center",
            },
          }}
        />
      </Paper>

      {/* Edit Dialog */}
      <Dialog
        open={editDialog.open}
        onClose={() => setEditDialog({ open: false, role: null })}
      >
        <DialogTitle>Edit Role</DialogTitle>
        <DialogContent>
          {editDialog.role && (
            <Formik
              initialValues={{
                roleName: editDialog.role.name,
                permissions: editDialog.role.permissions.reduce(
                  (acc, perm) => ({ ...acc, [perm]: true }),
                  {}
                ),
              }}
              validationSchema={Yup.object().shape({
                roleName: Yup.string().required("Role name is required"),
                permissions: Yup.object().test(
                  "at-least-one",
                  "At least one permission is required",
                  (obj) => Object.values(obj).some(Boolean)
                ),
              })}
              onSubmit={handleEditSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="roleName"
                    as={TextField}
                    label="Role Name"
                    fullWidth
                    margin="normal"
                    error={Boolean(touched.roleName && errors.roleName)}
                    helperText={touched.roleName && errors.roleName}
                  />

                  {permissionsData.map((parent) => (
                    <PermissionsGroup key={parent.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={parent.childrens.every(
                              (child) => values.permissions[child]
                            )}
                            indeterminate={
                              parent.childrens.some(
                                (child) => values.permissions[child]
                              ) &&
                              !parent.childrens.every(
                                (child) => values.permissions[child]
                              )
                            }
                            onChange={(e) => {
                              parent.childrens.forEach((child) =>
                                setFieldValue(
                                  `permissions.${child}`,
                                  e.target.checked
                                )
                              );
                            }}
                          />
                        }
                        label={parent.name}
                      />
                      <Box pl={4}>
                        {parent.childrens.map((child) => (
                          <FormControlLabel
                            key={child}
                            control={
                              <Checkbox
                                checked={Boolean(values.permissions[child])}
                                onChange={handleChange}
                                name={`permissions.${child}`}
                              />
                            }
                            label={child}
                          />
                        ))}
                      </Box>
                    </PermissionsGroup>
                  ))}

                  {errors.permissions && touched.permissions && (
                    <FormHelperText error>{errors.permissions}</FormHelperText>
                  )}

                  <DialogActions>
                    <Button
                      onClick={() => setEditDialog({ open: false, role: null })}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Save Changes
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, role: null })}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the role "{deleteDialog.role?.name}
            "? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog({ open: false, role: null })}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bulk Delete Confirmation Dialog */}
      <Dialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
      >
        <DialogTitle>Confirm Bulk Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selected.length} selected roles?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBulkDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={handleBulkDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete {selected.length} Roles
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default RolesList;
